import { createSlice } from '@reduxjs/toolkit'

const getInitialState = () => {
  const storedData = JSON.parse(localStorage.getItem('userPreferences'))
  return storedData
    ? { isFetching: false, error: null, data: storedData }
    : { isFetching: true, error: null, data: { language: 'ru', theme: 'dark' } }
}

const initialState = getInitialState()

const reducers = {
  changeTheme: (state, action) => {
    state.data.theme = action.payload.theme
    localStorage.setItem('userPreferences', JSON.stringify(state.data))
  },
  changeLanguage: (state, action) => {
    state.data.language = action.payload.language
    localStorage.setItem('userPreferences', JSON.stringify(state.data))
  },
  clearUserStore: state => {
    state.error = null
    state.data = { language: 'ru', theme: 'dark' }
    localStorage.setItem('userPreferences', JSON.stringify(state.data))
  },
  clearUserError: state => {
    state.error = null
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers
})

const { actions, reducer } = userSlice

export const { changeTheme, changeLanguage, clearUserStore, clearUserError } =
  actions

export default reducer
