import { combineReducers } from 'redux'
import userReducer from './slices/userSlice'
import languageReducer from './slices/languageSlice'
import recordReducer from './slices/recordSlice'
import jsonDataReducer from './slices/jsonDataSlice'

const rootReducer = combineReducers({
  userStore: userReducer,
  languageStore: languageReducer,
  recordStore: recordReducer,
  jsonDataStore: jsonDataReducer
})

export default rootReducer
