import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './ScrollBanner.module.sass'
import './ScrollBanner.sass'
import { connect, useSelector } from 'react-redux'

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

function ScrollBanner ({ user, records, page }) {
  const [aosEffect1, setAosEffect1] = useState('')
  const [aosEffect2, setAosEffect2] = useState('')
  const [aosDelay, setAosDelay] = useState('')
  const { language } = user.data
  const slideContent = useSelector(
    state => state.jsonDataStore.jsonData.opportunities
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setAosEffect1('fade-right')
        setAosEffect2('fade-left')
        setAosDelay('400')
      } else {
        setAosEffect1('')
        setAosEffect2('')
        setAosDelay('')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          key={`aosEffect2${aosEffect2}`}
          data-aos={aosEffect2}
          data-aos-delay={aosDelay}
          className={styles.textContent}
        >
          <h3 className={styles.title}>
            {records[page]?.ScrollBanner_Title?.[language]}
          </h3>
          <p className={styles.underTitleText}>
            {records[page]?.ScrollBanner_UnderTitle1?.[language]}
          </p>
          <p className={styles.underTitleText}>
            {records[page]?.ScrollBanner_UnderTitle2?.[language]}
          </p>
        </div>
        <div
          key={`aosEffect1${aosEffect1}`}
          data-aos={aosEffect1}
          data-aos-delay={aosDelay}
          className={`${styles.sliderContent} sliderContent`}
        >
          <Slider {...settings}>
            {slideContent &&
              slideContent.map((slide, index) => (
                <div key={index} className={styles.sliderItem}>
                  <div className={styles.sliderItemContent}>
                    <div className={styles.sliderTitle}>
                      <div className={styles.imgDiv}>
                        <img src={`/images/${slide.img}`} alt='img' />
                      </div>
                      <p className={styles.sliderName}>
                        {records[page]?.[slide.name]?.[language]}
                      </p>
                    </div>
                    <p className={styles.sliderText}>
                      {records[page]?.[slide.text]?.[language]}
                    </p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.userStore,
  records: state.recordStore.records,
  page: state.recordStore.page
})

export default connect(mapStateToProps, null)(ScrollBanner)
