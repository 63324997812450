import React from 'react'
import { useSelector } from 'react-redux'
import styles from './CategoryBox.module.sass'

function CategoryBox ({ category, index }) {
  const language = useSelector(state => state.userStore.data.language)
  const records = useSelector(state => state.recordStore.records)
  const page = useSelector(state => state.recordStore.page)

  return (
    <div className={styles.itemContainer}>
      <img
        src={`/images/${category.categoryImg}`}
        alt={category.categoryImg}
        className={styles.itemImg}
      />
      <div className={styles.itemContent}>
        <p className={styles.itemTitle}>{category.categoryName}</p>
        <div className={styles.itemShortDesc}>
          <div className={styles.twoBoxDesc}>
            <div className={styles.oneBoxDesc}>
              <img
                src='/images/Time_fill.png'
                alt='itemDescImg'
                className={styles.itemDescImg}
              />
              <span className={styles.itemDescText}>
                {category.categoryHours}{' '}
                {records[page]?.WhatYouCanFind_ItemDesc1?.[language]}
              </span>
            </div>
            <div className={styles.oneBoxDesc}>
              <img
                src='/images/User_fill_Banner.png'
                alt='itemDescImg'
                className={styles.itemDescImg}
              />
              <span className={styles.itemDescText}>
                {category.categoryStudents}{' '}
                {records[page]?.WhatYouCanFind_ItemDesc2?.[language]}
              </span>
            </div>
          </div>
          <div className={styles.twoBoxDesc}>
            <div className={styles.oneBoxDesc}>
              <img
                src='/images/Subtract.png'
                alt='itemDescImg'
                className={styles.itemDescImg}
              />
              <span className={styles.itemDescText}>
                {category.categoryCourses}{' '}
                {records[page]?.WhatYouCanFind_ItemDesc3?.[language]}
              </span>
            </div>
            <div className={styles.oneBoxDesc}>
              <img
                src='/images/Book_check_fill.png'
                alt='itemDescImg'
                className={styles.itemDescImg}
              />
              <span className={styles.itemDescText}>
                {category.categoryArticles}{' '}
                {records[page]?.WhatYouCanFind_ItemDesc4?.[language]}
              </span>
            </div>
          </div>
        </div>
        <p className={styles.itemDesc}>{category.categoryText}</p>
      </div>
    </div>
  )
}

export default CategoryBox
