import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './Footer.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { changeTheme } from '../../store/slices/userSlice'
import LanguageSelector from '../LanguageSelector'
import Wave from '../../components/Wave'
import themes from '../../store/DataJSON/Themes.json'

function Footer () {
  const dispatch = useDispatch()
  const { language, theme } = useSelector(state => state.userStore.data)
  const records = useSelector(state => state.recordStore.records)
  const page = useSelector(state => state.recordStore.page)
  const links = useSelector(state => state.jsonDataStore.jsonData.links)

  const handleThemeChange = newTheme => {
    if (theme !== newTheme) {
      dispatch(changeTheme({ theme: newTheme }))
    }
  }

  useEffect(() => {
    const updateThemeVariables = () => {
      const themeVariables = themes[theme]
      for (const [key, value] of Object.entries(themeVariables)) {
        document.documentElement.style.setProperty(key, value)
      }
    }

    updateThemeVariables()
  }, [theme])

  return (
    <footer className={styles.container}>
      <Wave />
      <div className={styles.contentBox}>
        <div className={styles.content}>
          <a
            href={links?.buttons?.Logo_Btn?.url ?? '/'}
            className={styles.logoBox}
          >
            <img src='/images/LearnHub_logo.png' alt='LearnHub_logo' />
          </a>
          <div className={styles.footeContent}>
            <div className={styles.navigationBox}>
              <p className={styles.title}>
                {records[page]?.Footer_BoxTitle1?.[language]}
              </p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                {links &&
                  links.navigations &&
                  Object.entries(links.navigations).map(
                    ([key, item], index) => (
                      <a
                        href={item.url}
                        key={index}
                        className={`${styles.hoverBtn} ${
                          page === item.page ? styles.activeBtn : ''
                        }`}
                      >
                        {records[page]?.[key]?.[language]}
                      </a>
                    )
                  )}
              </div>
            </div>
            <div className={styles.socialBox}>
              <p className={styles.title}>
                {records[page]?.Footer_BoxTitle2?.[language]}
              </p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                {links &&
                  links.socials &&
                  Object.entries(links.socials).map(([key, item], index) => (
                    <a href={item.url} key={index} className={styles.hoverBtn}>
                      <span className={styles.tooltip}>{key}</span>
                      <img src={`/images/${item.img}`} alt={key} />
                      <span>{key}</span>
                    </a>
                  ))}
              </div>
            </div>
            <div className={styles.settingsBox}>
              <p className={styles.title}>
                {records[page]?.Footer_BoxTitle3?.[language]}
              </p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                {links &&
                  links.policies &&
                  Object.entries(links.policies).map(([key, item], index) => (
                    <a href={item.url} key={index} className={styles.hoverBtn}>
                      {records[page]?.[key]?.[language]}
                    </a>
                  ))}
              </div>
            </div>
            <div className={styles.settingsBox}>
              <p className={styles.title}>
                {records[page]?.Footer_BoxTitle4?.[language]}
              </p>
              <div className={styles.line}></div>
              <div className={styles.flexBox}>
                <div
                  onClick={() =>
                    handleThemeChange(theme === 'light' ? 'dark' : 'light')
                  }
                  className={styles.themeBox}
                >
                  <span className={styles.themeText}>
                    {records[page]?.ThemeSelector?.[language]}
                  </span>
                  {theme === 'light' ? (
                    <FontAwesomeIcon className={styles.icon} icon={faSun} />
                  ) : (
                    <FontAwesomeIcon className={styles.icon} icon={faMoon} />
                  )}
                </div>
                <div className={styles.languageBox}>
                  <span className={styles.languageText}>
                    {records[page]?.LanguageSelector?.[language]}
                  </span>
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.disclaimer}>
          © 2024. LearnHub. All Rights Reserved. Prices excl. VAT.
        </div>
      </div>
    </footer>
  )
}

export default Footer
