import axios from 'axios'

export const fetchLanguages = async () =>
  await axios.get(`https://learnhub.syntaxjuggler.com/bff/api/language`)

export const fetchRecordsByPageAndLanguage = async ({ page, language }) =>
  await axios.get(
    `https://learnhub.syntaxjuggler.com/bff/api/record/translation/${page}/${language}`
  )

export const fetchJsonData = async () =>
  await axios.get(`https://learnhub.syntaxjuggler.com/bff/api/jsondata`)
