import React, { useState } from 'react'
import styles from './Header.module.sass'
import { useSelector } from 'react-redux'
import WaveDown from '../../components/WaveDown'
import MenuHeader from '../MenuHeader'

function Header () {
  const { language } = useSelector(state => state.userStore.data)
  const records = useSelector(state => state.recordStore.records)
  const page = useSelector(state => state.recordStore.page)
  const [menu, setMenu] = useState(false)
  const links = useSelector(state => state.jsonDataStore.jsonData.links)

  return (
    <header className={styles.header}>
      <WaveDown />
      <div className={styles.navigation}>
        <div className={styles.logo}>
          <a
            href={links?.buttons?.Logo_Btn?.url ?? '/'}
            className={styles.imgBox}
          >
            <img src='/images/LearnHub_logo.png' alt='LearnHub_logo' />
          </a>
        </div>
        <div className={styles.navigationBtn}>
          {links &&
            links.navigations &&
            Object.entries(links.navigations).map(([key, item], index) => (
              <a
                href={item.url}
                key={index}
                className={`${styles.hoverBtn} ${
                  page === item.page ? styles.activeBtn : ''
                }`}
              >
                {records[page]?.[key]?.[language]}
              </a>
            ))}
        </div>
        <div className={styles.menuBox}>
          <div className={styles.userBtn}>
            <div className={styles.imgBox}>
              <img
                className='userImg'
                src='/images/User_fill.png'
                alt='User_fill.png'
              />
            </div>
            <span>Войти</span>
          </div>
          <div
            className={`${styles.menuBtn} menuBtn`}
            onClick={() => setMenu(prev => !prev)}
          >
            <img
              className={styles.menuIcon}
              src='/images/IconMenu.png'
              alt='IconMenu.png'
            />
          </div>
        </div>
        {menu && <MenuHeader menu={menu} setMenu={setMenu} />}
      </div>
    </header>
  )
}

export default Header
