import React from 'react'
import styles from './ErrorBox.module.sass'

const ErrorBox = ({ error, onHide }) => {
  return (
    <div className={styles.errorNotification} onClick={onHide}>
      <svg
        stroke='currentColor'
        viewBox='0 0 24 24'
        fill='none'
        className={styles.icon}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
          strokeWidth='2'
          strokeLinejoin='round'
          strokeLinecap='round'
        ></path>
      </svg>
      <div className={styles.errorContent}>
        <div className={styles.errorCodeStatus}>
          <p className={styles.errorCode}>{error.data.errors[0]}</p>
          <p className={styles.errorStatus}>{error.data.status}</p>
        </div>
        <p className={styles.errorMessage}>{error.data.message}</p>
      </div>
      <div className={styles.progressBar}></div>
    </div>
  )
}

export default ErrorBox
