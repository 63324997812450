import React, { createContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../store/slices/userSlice'
import { fetchLanguages } from '../store/slices/languageSlice'
import { fetchRecords } from '../store/slices/recordSlice'

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.userStore)
  const languages = useSelector(state => state.languageStore.languages)
  const records = useSelector(state => state.recordStore.records)
  const page = useSelector(state => state.recordStore.page)
  const languageError = useSelector(state => state.languageStore.error)
  const recordError = useSelector(state => state.recordStore.error)
  const { language } = user.data

  useEffect(() => {
    if (languages.length === 0 && !languageError) {
      dispatch(fetchLanguages())
    }
  }, [dispatch, languages.length, languageError])

  const hasTranslation = useMemo(() => {
    return !!(
      records[page] &&
      records[page][Object.keys(records[page])[0]] &&
      records[page][Object.keys(records[page])[0]][language]
    )
  }, [records, page, language])

  useEffect(() => {
    if (!!page && !hasTranslation && !recordError) {
      dispatch(fetchRecords({ page, language }))
    }
  }, [dispatch, page, language, hasTranslation, recordError])

  const handleChangeLanguage = newLanguage => {
    if (language !== newLanguage) {
      dispatch(changeLanguage({ language: newLanguage }))
    }
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        languages,
        toggleLanguage: handleChangeLanguage,
        hasTranslation
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
