import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import { useSelector } from 'react-redux'
import Loader from '../Loader'
import ErrorNotification from '../ErrorNotification'

function Layout () {
  const languageIsFetching = useSelector(
    state => state.languageStore.isFetching
  )
  const recordIsFetching = useSelector(state => state.recordStore.isFetching)
  const jsonDataIsFetching = useSelector(
    state => state.jsonDataStore.isFetching
  )
  return (
    <>
      {!recordIsFetching && !languageIsFetching && !jsonDataIsFetching && (
        <ErrorNotification />
      )}
      {(languageIsFetching || recordIsFetching || jsonDataIsFetching) && (
        <Loader />
      )}
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout
