import React, { useState, useRef, useMemo, useContext, useEffect } from 'react'
import styles from './LanguageSelector.module.sass'
import { LanguageContext } from '../../context/LanguageContext'

const LanguageSelector = () => {
  const { language, languages, toggleLanguage } = useContext(LanguageContext)

  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelect = value => {
    if (language !== value) {
      toggleLanguage(value)
    }
    setIsOpen(false)
  }

  const languageOptions = useMemo(() => {
    return languages.map(lang => ({
      code: lang.code.toUpperCase(),
      value: lang.code
    }))
  }, [languages])

  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.customSelect} ref={selectRef}>
      <div className={styles.selected} onClick={toggleDropdown}>
        {language.toUpperCase()}
      </div>
      {isOpen && (
        <ul className={styles.optionsList}>
          {languageOptions.map(lang => (
            <li
              key={lang.value}
              className={styles.option}
              onClick={() => handleSelect(lang.value)}
            >
              <span>{lang.code}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default LanguageSelector
