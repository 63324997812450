import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import styles from './StarGenerator.module.sass'

function StarGenerator (rating) {
  const stars = []
  const totalStars = 5

  for (let i = 0; i < totalStars; i++) {
    const starClass = i < rating ? styles.starFilled : styles.starEmpty
    stars.push(<FontAwesomeIcon key={i} icon={faStar} className={starClass} />)
  }

  return stars
}

export default StarGenerator
