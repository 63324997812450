import React, { useEffect } from 'react'
import styles from './WaveDown.module.sass'

const WaveDown = () => {
  useEffect(() => {
    const svg = document.getElementById('svgDown')

    const renderWave = () => {
      svg.innerHTML = ''
      const screenWidth = window.innerWidth
      let amp = 4

      if (screenWidth <= 600) {
        amp = 10
      } else if (screenWidth <= 1300) {
        amp = 8
      }

      wave(amp, 50, 4 + 1 * Math.random(), 0.1, 10)
    }

    const wave = (amp, freq, time, scaleY, translateY) => {
      let pts = []
      for (let x = 0; x < 2000; x += 10) {
        pts.push([x, (Math.sin(x / freq) * amp + translateY).toFixed(1)])
      }
      svg.innerHTML += `<path class=${styles.wave} d="M0,0L${pts.join(
        'L'
      )}L2000,0Z"
        style="--t:${time}s;--sy:${scaleY};--tx:-${freq * 2 * Math.PI}px" />`
    }

    renderWave()
    window.addEventListener('resize', renderWave)

    return () => {
      window.removeEventListener('resize', renderWave)
    }
  }, [])

  return (
    <div className={styles.waveContainer}>
      <svg
        id='svgDown'
        viewBox='0 0 1000 1000'
        preserveAspectRatio='none'
      ></svg>
    </div>
  )
}

export default WaveDown
