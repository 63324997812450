import React from 'react'
import styles from './ComeToUs.module.sass'
import { connect, useSelector } from 'react-redux'

function ComeToUs ({ user, records, page }) {
  const { language } = user.data
  const links = useSelector(state => state.jsonDataStore.jsonData.links)
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.text}>
          <span className={styles.blueText}>
            {records[page]?.ComeToUs_BluText1?.[language]}
          </span>{' '}
          {records[page]?.ComeToUs_Text1?.[language]}{' '}
          <span className={styles.blueText}>
            {records[page]?.ComeToUs_BluText2?.[language]}
          </span>{' '}
          {records[page]?.ComeToUs_Text2?.[language]}{' '}
          <span className={styles.blueText}>
            {records[page]?.ComeToUs_BluText3?.[language]}
          </span>{' '}
          {records[page]?.ComeToUs_Text3?.[language]}
        </p>
        <a
          href={links?.buttons?.ComeToUs_Btn?.url ?? '/'}
          className={styles.btn}
        >
          <span className={styles.btnText}>
            {records[page]?.ComeToUs_Btn?.[language]}
          </span>
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.userStore,
  records: state.recordStore.records,
  page: state.recordStore.page
})

export default connect(mapStateToProps, null)(ComeToUs)
