import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import StarGenerator from '../StarGenerator'
import './ReviewsList.sass'
import styles from './ReviewList.module.sass'
import { connect, useSelector } from 'react-redux'

const settings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
}

function ReviewList ({ user, records, page }) {
  const { language } = user.data
  const reviewsContent = useSelector(
    state => state.jsonDataStore.jsonData.reviews
  )
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={`${styles.sliderContent} rewievContent`}>
          <Slider {...settings}>
            {reviewsContent &&
              reviewsContent.map((slide, index) => (
                <div key={index} className={styles.sliderItem}>
                  <div className={styles.flexRow}>
                    <p className={styles.userName}>{slide.userName}</p>
                    <div className={styles.dateBox}>
                      <span className={styles.tooltip}>
                        {records[page]?.ReviewList_TooltipDate?.[language]}
                      </span>
                      <img
                        className={styles.dateImg}
                        src='/images/date.png'
                        alt='date.png'
                      />
                      <p className={styles.date}>{slide.date}</p>
                    </div>
                  </div>
                  <div className={styles.flexRow}>
                    <div className={styles.starsBox}>
                      <span className={styles.tooltip}>
                        {records[page]?.ReviewList_TooltipRating?.[language]}
                      </span>
                      {StarGenerator(slide.rating)}
                    </div>
                    <p className={styles.category}>{slide.category}</p>
                  </div>
                  <p className={styles.text}>{slide.text}</p>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.userStore,
  records: state.recordStore.records,
  page: state.recordStore.page
})

export default connect(mapStateToProps, null)(ReviewList)
