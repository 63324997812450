import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import deepMerge from '../../utils/deepMerge'
import * as API from '../../api'

export const fetchRecords = createAsyncThunk(
  'records/fetchRecordsByPageAndLanguage',
  async ({ page, language }, { rejectWithValue }) => {
    try {
      const { data } = await API.fetchRecordsByPageAndLanguage({
        page,
        language
      })
      return data
    } catch (err) {
      const errorData = {
        status: err?.response?.status ?? 504,
        errors: err?.response?.data?.errors ?? ['Gateway Timeout'],
        message:
          err?.response?.data?.message ??
          'Failed to load translations. Please try again later'
      }
      return rejectWithValue({ data: errorData })
    }
  }
)

const initialState = {
  records: {},
  page: '',
  isFetching: false,
  error: null
}

const reducers = {
  clearRecordError: (state, action) => {
    state.error = null
  },
  changePage: (state, action) => {
    state.page = action.payload.page
  }
}

const recordSlice = createSlice({
  name: 'record',
  initialState,
  reducers,
  extraReducers: builder => {
    builder
      .addCase(fetchRecords.pending, state => {
        state.isFetching = true
        state.error = null
      })
      .addCase(fetchRecords.fulfilled, (state, action) => {
        state.isFetching = false
        state.records = deepMerge(state.records, action.payload)
      })
      .addCase(fetchRecords.rejected, (state, action) => {
        state.isFetching = false
        state.error = action.payload?.data ?? action.error.message
      })
  }
})

const { actions, reducer } = recordSlice

export const { clearRecordError, changePage } = actions

export default reducer
